import React from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import Seo from "../components/Seo"
import { GridRow, GridCol } from "../components/grid"
import Link from "../components/Link"
import Text from "../components/Text"
import Button, { ButtonVariant } from "../components/Button"

import noobwLogo from "../images/noobw-logo.svg"
import NotFoundImage from "../images/404.svg"

const NotFoundPage: React.FC = () => (
  <>
    <Seo title="Page not found" />
    <LogoWrapper>
      <GridCol s={1} m={3} mOffsetLeft={1} l={3} lOffsetRight={1}>
        <Link type="internal" url="/">
          <StyledLogo src={noobwLogo} alt="Not On Our Border Watch logo" />
        </Link>
      </GridCol>
    </LogoWrapper>
    <Container>
      <Wrapper m={5} l={4}>
        <StyledImg src={NotFoundImage} alt="404, page not found" />
        <StyledText
          tag="h1"
          textStyle="subHeading"
          textAlign="center"
          marginBottom={80}
        >
          Oh oh, we cannot find the page you were looking for
        </StyledText>
        <StyledButton
          variant={ButtonVariant.Filled}
          caption="Go back"
          color="black"
          hoverColor="white"
          textColor="white"
          hoverTextColor="black"
          hoverBorderColor="black"
          onClick={() => navigate(-1)}
        />
      </Wrapper>
    </Container>
  </>
)

const LogoWrapper = styled(GridRow)`
  padding-top: 24px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding-top: 56px;
    `
  )}
`

const StyledLogo = styled.img`
  margin: 0;
  width: 100%;
`

const Container = styled(GridRow)`
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 372px);
  padding-top: 80px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      min-height: calc(100vh - 475px);
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      min-height: calc(100vh - 536px);
    `
  )}
`

const Wrapper = styled(GridCol)`
  text-align: center;
`

const StyledImg = styled.img`
  margin: 0 auto 32px;
  width: 50%;

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 40px;
    `
  )}
`

const StyledText = styled(Text)`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 120px;
    `
  )}
`

const StyledButton = styled(Button)`
  width: 100%;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin: 0 auto;
      width: 60%;
    `
  )}
`

export default NotFoundPage
