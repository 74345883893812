import React, { useState } from "react"
import styled, { css } from "styled-components"

// Components
import Icon, { IconType } from "./icons/Icon"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/typography"
import { fastTransition } from "../styles/effects"

// Types
import { Color } from "../common/models/colors"

export enum ButtonVariant {
  Filled = "filled",
  Text = "text",
  Outlined = "outlined", // Meaning: text & border in the same color (currently unused)
}

export interface IProps {
  disabled?: boolean
  caption?: string | React.ReactNode
  hoverCaption?: string | React.ReactNode
  icon?: IconType
  variant?: ButtonVariant
  color?: Color
  textColor?: Color
  hoverColor?: Color
  hoverTextColor?: Color
  hoverBorderColor?: Color
  activeBorderColor?: Color
  withUnderline?: boolean
  iconSize?: number
  onClick?: (event?: React.MouseEvent) => void
}

const Button: React.FCS<IProps> = (props) => {
  const caption = props.caption || ""
  const hoverCaption = props.hoverCaption || caption
  const color = props.color ?? "primary"
  const textColor = props.textColor ?? "black"
  const hoverColor = colors[props.hoverColor ?? (props.color || "primaryLight")]
  const hoverTextColor = props.hoverTextColor ?? "black"
  const variant = props.variant ? props.variant : ButtonVariant.Filled
  const iconColor = props.textColor
    ? colors[props.textColor]
    : variant === ButtonVariant.Filled
    ? colors.black
    : colors[color]
  const iconSize = props.iconSize ?? 18

  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <StyledButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
      color={color}
      variant={variant}
      type="button"
      textColor={textColor}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      hoverBorderColor={props.hoverBorderColor}
      activeBorderColor={props.activeBorderColor}
    >
      {props.icon !== undefined && (
        <Icon size={iconSize} color={iconColor} type={props.icon} />
      )}
      {!!caption && (
        <ButtonLabel>{isHovered ? hoverCaption : caption}</ButtonLabel>
      )}
    </StyledButton>
  )
}

export const StyledButton = styled.button<
  Pick<
    IProps,
    | "variant"
    | "color"
    | "withUnderline"
    | "textColor"
    | "hoverTextColor"
    | "hoverBorderColor"
    | "activeBorderColor"
  > & {
    hoverColor: string
  }
>`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px 32px;
  border: 1px solid transparent;
  border-radius: 2px;
  outline: none;
  text-decoration: ${(props) => (props.withUnderline ? "underline" : "none")};
  cursor: pointer;
  transition: color ${fastTransition}, background-color ${fastTransition},
    border-color ${fastTransition};

  ${(props) =>
    props.hoverBorderColor &&
    css`
      &:hover {
        border-color: ${props.hoverBorderColor};
      }
    `}

  ${(props) =>
    props.activeBorderColor &&
    css`
      &:active,
      &:focus {
        border-color: ${props.activeBorderColor};
      }
    `}

  &:disabled {
    cursor: default;
    pointer-events: none;

    &:active,
    &:focus,
    &:hover {
      border-color: transparent;
    }

    label {
      cursor: default;
    }
  }

  ${(props) =>
    props.variant === "filled" &&
    css`
      color: ${colors[props.textColor!]};
      background-color: ${colors[props.color!]};

      &:hover {
        background-color: ${props.hoverColor};
        color: ${colors[props.hoverTextColor!]};

        svg > path {
          fill: ${colors[props.hoverTextColor!]};
        }
      }

      &:disabled {
        background-color: ${colors.disabledBg};
        color: ${colors.white};
      }
    `}

  ${(props) =>
    props.variant === "text" &&
    css`
      color: ${colors[props.color!]};
      background-color: transparent;
      padding: 0px;

      &:hover {
        color: ${props.hoverColor};

        svg > path {
          fill: ${props.hoverColor};
        }
      }

      &:disabled {
        color: ${colors.disabled};

        svg > path {
          fill: ${colors.disabled};
        }
      }
    `}
`

const ButtonLabel = styled.label`
  ${textStyles.label}
  cursor: pointer;
`

export default Button
