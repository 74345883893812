// Any reusable css effects

export const shadows = {
  depthOne: `0px 1px 0px 0px rgba(42, 9, 134, 0.10)`,
  depthTwo: `0px 2px 1px rgba(42, 9, 134, 0.05), 0px 0px 1px rgba(42, 9, 134, 0.25)`,
  depthThree: `0px 0px 2px rgba(42, 9, 134, 0.2), 0px 2px 10px rgba(42, 9, 134, 0.1)`,
  depthFive: `0px 0px 4px rgba(42, 9, 134, 0.08), 0px 8px 40px rgba(42, 9, 134, 0.16)`,
}

export const fastDuration = 240
export const slowDuration = 400

export const fastTransition = `${fastDuration}ms ease-in-out`
export const slowTransition = `${slowDuration}ms ease-in-out`
